import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-grid-system';
import breakpoints from 'utils/breakpoints';
import Card from 'components/Card';
import { ReactComponent as BenefitsIcon } from 'assets/careers/benefits.svg';
import { ReactComponent as ChallengeIcon } from 'assets/careers/challenge.svg';
import { ReactComponent as TopOneIcon } from 'assets/careers/top1.svg';
import Paragraph from 'components/Paragraph';

const StyledRow = styled(Row)`
  margin: 0;
  background: #fff;
`;

const ValueItem = styled.div`
  box-sizing: border-box;
  padding: 0.5rem 0;
  height: 100%;
  margin-bottom: 30px;

  @media (min-width: ${breakpoints.md}) {
    padding: 1rem 0;
    margin-bottom: 0;
  }
`;

const StyledCard = styled(Card)`
  align-items: center;
  background: none;
  padding: 0 3.56rem;
  text-align: center;
`;

const CardText = styled(Paragraph)`
  margin: 0;
`;

function ValueList() {
  return (
    <StyledRow>
      <Col md={6} lg={4}>
        <ValueItem>
          <StyledCard icon={<BenefitsIcon />} title="Benefit besar ">
            <CardText>
              Kami menawarkan benefit kelas dunia kepada para karyawan kami,
              dan juga memastikan bahwa kami mendukung keseimbangan kehidupan
              semua orang
            </CardText>
          </StyledCard>
        </ValueItem>
      </Col>
      <Col md={6} lg={4}>
        <ValueItem>
          <StyledCard icon={<TopOneIcon />} title="Kami hanya mempekerjakan top 1%">
            <CardText>
              Proses perekrutan kami dirancang untuk memastikan bahwa kami
              hanya mempekerjakan talenta terbaik. Anda akan bekerja bersama
              orang orang terpintar di industri ini
            </CardText>
          </StyledCard>
        </ValueItem>
      </Col>
      <Col md={6} lg={4}>
        <ValueItem>
          <StyledCard icon={<ChallengeIcon />} title="Tantangan yang menarik">
            <CardText>
              Trusting Social mendorong pengembangan karir anda dan pada saat
              yang sama, anda bisa memberikan perubahan pada kehidupan orang lain
            </CardText>
          </StyledCard>
        </ValueItem>
      </Col>
    </StyledRow>
  );
}

export default ValueList;
