import React, { useRef, useState, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AiOutlinePaperClip } from 'react-icons/ai';

const Wrapper = styled.label`
  position: relative;
  display: flex;
  color: #0276f1;
  align-items: center;
  font-size: 14px;
`;

const PaperClip = styled(AiOutlinePaperClip)`
  width: 20px;
  height: 20px;
  margin-right: 9px;
`;

const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

const Label = styled.span`
  font-size: 1rem;

  ${Wrapper}:hover & {
    text-decoration: underline;
  }
`;

const FileUploader = ({ className, id, label, setFieldValue, name }) => {
  const ref = useRef();
  const [file, setFile] = useState();
  return (
    <Wrapper className={className} htmlFor={id}>
      <PaperClip />
      <Label>{file ? file.name : label}</Label>
      <Input
        type="file"
        ref={ref}
        id={id}
        name={name}
        accept="image/*,.doc,.docx,.ppt,.pptx,.txt,.pdf"
        onChange={e => {
          const newFile = e.currentTarget.files[0];
          setFile(newFile);
          setFieldValue(name, newFile);
        }}
      />
    </Wrapper>
  );
};
FileUploader.defaultProps = {
  className: null,
  id: null,
  label: null,
};

FileUploader.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default memo(FileUploader);
