import React, { useState, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const activeWrapperStyle = css`
  border-color: #0276f1;
`;

const Wrapper = styled.div`
  border-bottom: 1px solid #d4d4d4;
  position: relative;

  ${props => (props.active ? activeWrapperStyle : null)}
`;

const activeLabelStyle = css`
  top: -6px;
`;

const activeTextAreaLabelStyle = css`
  top: -10px;
`;

const FloatingLabel = styled.label`
  color: #8f8f8f;
  font-size: 12px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: top 0.1s ease;

  ${props => (props.active ? activeLabelStyle : null)}
`;

const TextAreaFloatingLabel = styled(FloatingLabel)`
  position: static;
  display: block;
  margin-bottom: 10px;
  transform: none;
  ${props => (props.active ? activeTextAreaLabelStyle : null)}
`;

const InputControl = styled.input`
  border: none;
  background: none;
  display: block;
  width: 100%;
  color: #1c2f45;
  font-size: 16px;
  line-height: 18px;
  padding: 10px 0;
  outline: none;
`;

const TextareaControl = styled.textarea`
  border: none;
  background: none;
  display: block;
  width: 100%;
  color: #1c2f45;
  font-size: 16px;
  line-height: 18px;
  outline: none;
  resize: none;
  min-height: 70px;
`;

const Input = ({
  className,
  label,
  id,
  inputType,
  value,
  onChange,
  name,
  ...props
}) => {
  const [active, setActive] = useState();
  const handleFocus = useCallback(() => setActive(true), []);
  const handleBlur = useCallback(() => setActive(false), []);
  const LabelComponent =
    inputType === 'input' ? FloatingLabel : TextAreaFloatingLabel;
  const ControlComponent =
    inputType === 'input' ? InputControl : TextareaControl;
  return (
    <Wrapper active={active} className={className}>
      {label && (
        <LabelComponent
          onClick={handleFocus}
          active={active || value}
          htmlFor={id}
        >
          {label}
        </LabelComponent>
      )}
      <ControlComponent
        onFocus={handleFocus}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        {...props}
        onBlur={handleBlur}
      />
    </Wrapper>
  );
};

Input.defaultProps = {
  className: null,
  label: null,
  id: null,
  value: '',
  name: '',
  inputType: 'input',
};

Input.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  inputType: PropTypes.oneOf(['input', 'textarea']),
};

const MemorizedInput = memo(Input);

export default MemorizedInput;
