import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import Button from 'components/Button';
import breakpoints from 'utils/breakpoints';
import { ReactComponent as PinIcon } from 'assets/icons/pin.svg';
import { ReactComponent as BriefcaseIcon } from 'assets/icons/briefcase.svg';

const ITEMS_PER_PAGE = 10;

const Table = styled.table`
  margin-top: 22px;
  border-collapse: collapse;
  width: 100%;

  th {
    padding: 5px 0;
    font-family: Helvetica;
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 0.3);
    letter-spacing: 0;
    line-height: 1.875rem;
    text-transform: uppercase;
  }

  td {
    padding: 23px 0;
    text-transform: capitalize;
    position: relative;

    &:first-child::before,
    &:last-child::before {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      width: 0;
      height: 100%;
      transition: 0.3s;
    }

    &:first-child::before {
      left: 0;
      border-radius: 5px 0 0 5px;
    }

    &:last-child::before {
      right: 0;
      border-radius: 0 5px 5px 0;
    }
  }

  th,
  td {
    text-align: left;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    &:last-child {
      text-align: right;
    }
  }
`;

const BodyRow = styled.tr`
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.15);

    td {
      color: #1c2f45;
      background: #ffffff;

      &:first-child::before,
      &:last-child::before {
        width: 1rem;
        background: #ffffff;
      }

      &:first-child::before {
        left: -1rem;
      }

      &:last-child::before {
        right: -1rem;
      }

      @media (min-width: ${breakpoints.md}) {
        &:first-child::before,
        &:last-child::before {
          width: 23px;
          background: #ffffff;
        }

        &:first-child::before {
          left: -23px;
        }

        &:last-child::before {
          right: -23px;
        }
      }
    }

    svg {
      color: rgba(28, 47, 69, 0.3);
      transition: 0.3s;
    }
  }
`;

const Title = styled.td`
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.875rem;
  transition: 0.3s;
  max-width: 140px;

  @media (min-width: ${breakpoints.md}) {
    max-width: none;
  }
`;

const Value = styled.td`
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.875rem;
  transition: 0.3s;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align};

  svg {
    color: rgba(255, 255, 255, 0.3);
    margin-right: 10px;
  }

  span {
    line-height: 1.2rem;
    font-size: 0.8rem;

    @media (min-width: ${breakpoints.md}) {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
`;

const LoadMore = styled(Button)`
  margin-top: 50px;
  border: 1px solid #ffffff;
  border-radius: 3px;
  padding-left: 35px;
  padding-right: 35px;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 80px;
  }
`;

function JobList({ jobs }) {
  const [page, setPage] = useState(1);
  const totalPage = Math.ceil(jobs.length / ITEMS_PER_PAGE);
  const hasMore = page < totalPage;

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Jabatan</th>
            <th>Lokasi</th>
            <th>Jenis Pekerjaan</th>
          </tr>
        </thead>
        <tbody>
          {jobs.slice(0, page * ITEMS_PER_PAGE).map(job => (
            <BodyRow
              key={job.rawID}
              onClick={() => navigate(`/careers/openings/${job.slug}`)}
            >
              <Title>{job.title}</Title>
              <Value>
                <Content>
                  <PinIcon />
                  <span>{job.location && job.location.city}</span>
                </Content>
              </Value>
              <Value>
                <Content align="flex-end">
                  <BriefcaseIcon />
                  <span>{job.position_type}</span>
                </Content>
              </Value>
            </BodyRow>
          ))}
        </tbody>
      </Table>
      {hasMore && (
        <LoadMore onClick={() => setPage(page + 1)}>Muat lebih banyak</LoadMore>
      )}
    </>
  );
}

JobList.propTypes = {
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      rawID: PropTypes.string,
      title: PropTypes.string,
      position_type: PropTypes.string,
      location: PropTypes.shape({
        city: PropTypes.string,
        state: PropTypes.string,
        country: PropTypes.string,
      }),
    })
  ),
};

JobList.defaultProps = {
  jobs: [],
};

export default JobList;
