import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';

const Wrapper = styled.blockquote`
  text-align: left;
  margin: 0;
  padding: 0 0 30px 0;
`;

const QuoteIcon = styled.i.attrs({ children: '“' })`
  float: left;
  padding: 22px 10px 0 0;
  font-size: 3.75rem;
  font-style: normal;
  font-weight: 800;
  color: #1c2f45;
  letter-spacing: 0;
  line-height: 0;
`;

const Content = styled.p`
  margin: 0 0 1.875rem;
  font-size: 0.875rem;
  line-height: 1.75rem;
  text-align: left;

  @media (min-width: ${breakpoints.md}) {
    font-size: 1rem;
  }
`;

const AuthorName = styled.strong`
  color: #1c2f45;
  display: block;
  font-weight: bold;
`;

const JobTitle = styled.p`
  color: #1c2f45;
  letter-spacing: 0;
  font-size: 14px;
  margin-top: 10px;
`;

function Quote({ className, content, author }) {
  return (
    <Wrapper className={className}>
      <Content>
        <QuoteIcon />
        {content}
      </Content>
      <AuthorName>{author.name}</AuthorName>
      <JobTitle>{`${author.title} @${author.company}`}</JobTitle>
    </Wrapper>
  );
}

Quote.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string.isRequired,
  author: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    company: PropTypes.string,
  }),
};

Quote.defaultProps = {
  className: null,
  author: null,
};

export default Quote;
