import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import SlickSlider from 'react-slick';
import breakpoints from 'utils/breakpoints';
import Heading from 'components/Heading';
import Quote from './Quote';
import * as QuoteImgs from './QuoteImgs';

const IMAGES = [<QuoteImgs.HoaHoang />, <QuoteImgs.HuongLuu />];

const Slider = styled(SlickSlider)`
  margin-bottom: 66px;

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 120px;
  }

  .slick-dots {
    text-align: left;

    li {
      margin: 0;

      button:before {
        color: #d8d8d8;
        opacity: 1;
      }

      &.slick-active button:before {
        color: #0276f1;
      }
    }
  }
`;

const ImageWrapper = styled.div`
  margin: 0 auto;
  max-width: 50vw;

  .lazy-load-image-background,
  .lazy-load-image-background img {
    height: 100%;
    display: block !important;
  }

  @media (min-width: ${breakpoints.md}) {
    height: 640px;
  }
`;

function ExpertQuotes() {
  const [index, setIndex] = useState(0);

  return (
    <Container>
      <Row align="end">
        <Col md={5} offset={{ md: 1 }}>
          <Row>
            <Col md={10}>
              <Heading
                name="Kekuatan Trusting Social"
                title="Kesempatan bekerja bersama ahlinya."
              />
            </Col>
          </Row>
          <Slider fade dots afterChange={i => setIndex(i)}>
            <Quote
              author={{
                name: 'Hoa Hoang',
                title: 'Software Engineer - Telco team',
                company: 'Trusting Social Vietnam',
              }}
              content="Bekerja di Trusting Social merupakan perjalanan yang menakjubkan bagi saya hingga saat ini. Hal yang paling saya hargai tentang perusahaan ini adalah bahwa saya diperbolehkan untuk bekerja di suasana kerja multi budaya yang menyenangkan bersama rekan rekan yang baik dan berbakat. Saya sangat bangga menjadi bagian dari Trusting Social."
            />
            <Quote
              author={{
                name: 'Callista Arsyad',
                title: 'Business Development',
                company: 'Trusting Social Indonesia',
              }}
              content="Bekerja di Trusting Social tidak hanya memungkinkan saya untuk berkembang sebagai kontributor yang efektif tetapi juga memberikan kesempatan unik dan berharga untuk berkolaborasi dengan para expert mendunia dalam membangun produk - produk disruptif dan inovatif. Saya merasa bangga dan bersyukur bisa menjadi bagian dari perusahaan yang tidak hanya fokus pada pertumbuhan bisnis, tetapi juga secara aktif memperhatikan dan berinvestasi dalam pertumbuhan setiap individunya."
            />
          </Slider>
        </Col>
        <Col md={4} offset={{ md: 1 }}>
          <ImageWrapper>{IMAGES[index]}</ImageWrapper>
        </Col>
      </Row>
    </Container>
  );
}

export default ExpertQuotes;
