import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import Button from 'components/Button';
import Input from 'components/Input';
import { Col, Row } from 'react-grid-system';
import breakpoints from 'utils/breakpoints';
import * as Yup from 'yup';
import FileUploader from './FileUploader';

const Wrapper = styled.div`
  background: #fff;
  padding: 1.88rem 1.5rem;

  @media screen and (min-width: ${breakpoints.md}) {
    padding: 3.75rem 4.06rem;
  }
`;

const Heading = styled.h4`
  color: #151515;
  font-size: 1.38rem;
  font-weight: 500;
  line-height: 2rem;
  margin: 0 0 50px 0;
  text-align: center;

  @media screen and (min-width: ${breakpoints.md}) {
    margin-bottom: 20px;
  }
`;

const FormWrapper = styled.div`
  @media screen and (min-width: ${breakpoints.md}) {
    padding: 0 40px;
  }
`;

const InputWrap = styled(Col)`
  margin-top: 1.88rem;
`;

const MessageInputWrap = styled(Col)`
  margin-top: 3.13rem;
  margin-bottom: 1.88rem;

  @media screen and (min-width: ${breakpoints.md}) {
    clear: both;
  }
`;

const ButtonWrap = styled(Col)`
  margin-bottom: 1.88rem;

  @media screen and (min-width: ${breakpoints.md}) {
    margin-bottom: 0;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 1.25rem;

  @media screen and (min-width: ${breakpoints.md}) {
    margin-top: 0;
  }
`;

const StyledErrorMessage = styled.p`
  color: #da0000;
  font-size: 0.8rem;
  margin-top: 5px;
  &::first-letter {
    text-transform: uppercase;
  }
`;

const initialValues = {
  email: '',
  name: '',
  phone: '',
  yearsOfExperiences: '',
  message: '',
  cvAttachment: null,
};

const applicationShema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(),
  name: Yup.string().required(),
  phone: Yup.number().typeError('Please input a valid phone number'),
  yearsOfExperiences: Yup.number()
    .min(0)
    .max(50),
  message: Yup.string().min(5),
  cvAttachment: Yup.mixed().required('CV is a required field'),
});

function ApplicationForm({ className }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [generalError, setGeneralError] = useState(null);
  const handleSubmit = async values => {
    setSuccess(false);
    setGeneralError(false);

    const body = new FormData();
    Object.keys(values).forEach(key => {
      if (values[key]) {
        body.append(key, values[key]);
      }
    });
    try {
      setLoading(true);
      await fetch(`${process.env.GATSBY_API_URL}/apply`, {
        method: 'POST',
        body,
      });
      setSuccess(true);
    } catch (error) {
      setGeneralError(error);
    } finally {
      setLoading(false);
    }
  };

  if (success) {
    return (
      <Wrapper className={className}>
        <p>
          Aplikasi anda telah dikirimkan kepada tim rekruitasi kami,
          kami akan mengontak anda segera!
        </p>
      </Wrapper>
    );
  }

  return (
    <Wrapper className={className}>
      <Heading>
        Apakah anda tidak menemukan lowongan yang cocok dengan skill anda?
        Kami masih berharap untuk menerima aplikasi anda :
      </Heading>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={applicationShema}
        render={({ setFieldValue }) => (
          <Form>
            <FormWrapper>
              <Row>
                <InputWrap md={6}>
                  <Field
                    as={Input}
                    name="name"
                    label="Nama anda"
                    id="applicationName"
                  />
                  <ErrorMessage component={StyledErrorMessage} name="name" />
                </InputWrap>
                <InputWrap md={6}>
                  <Field
                    as={Input}
                    name="email"
                    label="Email anda"
                    id="applicationEmail"
                    type="email"
                  />
                  <ErrorMessage component={StyledErrorMessage} name="email" />
                </InputWrap>
              </Row>
              <Row>
                <InputWrap md={6}>
                  <Field
                    as={Input}
                    name="phone"
                    label="Nomor telepon anda"
                    id="applicationPhone"
                    type="tel"
                  />
                  <ErrorMessage component={StyledErrorMessage} name="phone" />
                </InputWrap>
                <InputWrap md={6}>
                  <Field
                    as={Input}
                    label="Lama pengalaman bekerja"
                    type="number"
                    name="yearsOfExperiences"
                    min={0}
                    max={50}
                    id="applicationExp"
                  />
                  <ErrorMessage
                    component={StyledErrorMessage}
                    name="yearsOfExperiences"
                  />
                </InputWrap>
              </Row>
              <Row>
                <MessageInputWrap>
                  <Field
                    as={Input}
                    name="message"
                    label="Pesan anda untuk tim rekruitasi kami"
                    id="applicationMessage"
                    inputType="textarea"
                  />
                  <ErrorMessage component={StyledErrorMessage} name="message" />
                </MessageInputWrap>
              </Row>
              <Row align="center" justify="between">
                <Col md={6}>
                  <Field
                    setFieldValue={setFieldValue}
                    as={FileUploader}
                    name="cvAttachment"
                    id="cv"
                    label="Unggah CV anda"
                  />
                  <ErrorMessage
                    component={StyledErrorMessage}
                    name="cvAttachment"
                  />
                </Col>
                <ButtonWrap md={5} lg={4}>
                  <StyledButton disabled={loading} type="submit">
                    {loading ? 'Sedang diproses' : 'Kirim CV anda sekarang'}
                  </StyledButton>
                  {generalError && (
                    <div>
                      <StyledErrorMessage>
                        {generalError.message}
                      </StyledErrorMessage>
                    </div>
                  )}
                </ButtonWrap>
              </Row>
            </FormWrapper>
          </Form>
        )}
      />
    </Wrapper>
  );
}

ApplicationForm.defaultProps = {
  className: null,
};

ApplicationForm.propTypes = {
  className: PropTypes.string,
};

export default ApplicationForm;
