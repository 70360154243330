import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'components/Img';
import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';

const StyledImg = styled(Img)`
  width: 100%;
  @media screen and (min-width: ${breakpoints.md}) {
    width: auto;
    height: 100%;
  }
`;

function HoaImage() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "careers/testimonials/mrhoa.png" }) {
        publicURL
      }
    }
  `);
  return <StyledImg {...data.file} />;
}

export default HoaImage;
